<template>
  <div
    :class="[
      'cell',
      { '_row': !column },
      { '_column': column },
      { '_big': big },
      { '_min': min },
      { '_start': start},
      { '_center': center},
      { '_nowrap': nowrap},
      { '_full-width': toggleVisibility}
    ]"
  >
    <slot name="default"/>

    <div :class="['buttons', {
      '_full-width': toggleVisibility || filter,
      '_full-height': toggleVisibility || filter || sort
    }]">
      <template v-if="buttonsVisible">
        <div class="visibility__btn">
          <div @click="hideColumn">
            <icon
                v-if="showToggleVisibility"
                name="visibility_off"
            />
          </div>
        </div>
        <slot name="filter">
          <div
              v-if="filter"
              :class="['filter__btn', {'_active': activeFilter}]"
              :data-test="`filter_${filterOptions.metric.filterName}`"
              @click="$modal.open({
              name: MODAL_TITLE.METRIC_FILTER,
              transparent: true,
              props: filterOptions
            })"
          >
            <icon name="filter_list" />
          </div>
        </slot>
      </template>

      <slot name="sort">
        <div
            v-if="sort"
            :class="[
              'sort',
              {'__column' : sortColumn}
            ]"
        >
          <div
              class="sort__btn"
              :class="{'_active': activeSort.desc }"
              :data-test="`sort_${orderBy}_desc`"
              @click="sortList('DESC')"
          >
            <icon name="unfold_more_arrow"/>
          </div>
          <div
              class="sort__btn"
              :class="{'_active': activeSort.asc }"
              :data-test="`sort_${orderBy}_asc`"
              @click="sortList('ASC')"
          >
            <icon name="unfold_more_arrow"/>
          </div>
        </div>
      </slot>
    </div>

    <slot name="end"/>

    <v-hint
      v-if="hint || $slots.hint"
      :class="[
        '__offset-left',
        { '__right': !sort }
      ]"
      position="top-right"
    >
      <slot name="hint">
        <span v-html="hint"></span>
      </slot>
    </v-hint>
  </div>
</template>

<script>
import {isEqual} from 'underscore'
import {checkIsObject} from '@/function'
import MODAL_TITLE from '@/modals/modalTitle.js'

export default {
  inject: {
    tableCellsConfigKey: {
      default: ''
    }
  },

  props: {
    /* Отображение сортировки */
    sort: {
      type: Boolean,
      default: false
    },

    /* Отображение фильтра */
    filter: {
      type: Boolean,
      default: false
    },

    /* justify-content: flex-start */
    start: {
      type: Boolean,
      default: false
    },

    /* align-items: center */
    center: {
      type: Boolean,
      default: false
    },

    /* Контент построчно */
    column: {
      type: Boolean,
      default: false
    },

    /* Высокая ячейка */
    big: {
      type: Boolean,
      default: false
    },

    /* Низкая ячейка */
    min: {
      type: Boolean,
      default: false
    },

    /* Всплывающая подсказка */
    hint: {
      type: String,
      default: undefined
    },

    /* Иконки сортировки в столбик */
    sortColumn: {
      type: Boolean,
      default: false
    },

    orderBy: {
      type: String
    },

    orderByPropertyName: {
      type: String,
      default: 'order_by'
    },

    orderDirectionPropertyName: {
      type: String,
      default: 'order_direction'
    },

    orderValuePropertyName: {
      type: String,
      default: 'order_value'
    },

    orderValue: {
      type: String,
      default: 'value'
    },

    needOrderValue: {
      type: Boolean,
      default: false
    },

    nowrap: {
      type: Boolean,
      default: false
    },

    filterOptions: {
      type: Object,
      default: () => ({})
    },

    showButtons: {
      type: Boolean,
      default: false
    },

    toggleVisibility: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      MODAL_TITLE
    }
  },

  methods: {
    sortList (order_direction) {
      const router = {
        query: {
          ...this.$route.query
        }
      }

      router.query[this.orderByPropertyName] = this.orderBy
      router.query[this.orderDirectionPropertyName] = order_direction

      if (this.needOrderValue) {
        router.query[this.orderValuePropertyName] = this.orderValue
      }

      if (router.query.page) {
        router.query.page = undefined
      }

      if (!isEqual(router.query, this.$route.query)) {
        this.$router.push(router)
      }
    },

    hideColumn() {
      if (this.tableCellsConfigKey) {
        let tableCellsConfig = JSON.parse(localStorage.getItem(this.tableCellsConfigKey))

        if (
            tableCellsConfig &&
            this.filterOptions &&
            this.filterOptions.metric &&
            this.filterOptions.metric.id &&
            checkIsObject(tableCellsConfig) &&
            tableCellsConfig.hasOwnProperty(this.filterOptions.metric.id)
        ) {
          let newConfig = JSON.parse(localStorage.getItem(this.tableCellsConfigKey))

          newConfig = {
            ...newConfig,
            [this.filterOptions.metric.id]: false
          }

          this.$store.commit('tableSettings/saveTableCellsConfig', {
            key: this.tableCellsConfigKey,
            newConfig
          })
        }
      }
    }
  },

  computed: {
    activeSort () {
      const result = {
        asc: false,
        desc: false
      }

      if (!this.$route.query) return result
      if (!this.$route.query[this.orderByPropertyName]) return result
      if (!this.$route.query[this.orderDirectionPropertyName]) return result

      result.asc = this.$route.query[this.orderByPropertyName] === this.orderBy && this.$route.query[this.orderDirectionPropertyName] === 'ASC'
      result.desc = this.$route.query[this.orderByPropertyName] === this.orderBy && this.$route.query[this.orderDirectionPropertyName] === 'DESC'

      if (this.needOrderValue) {
        result.asc &= this.$route.query[this.orderValuePropertyName] === this.orderValue
        result.desc &= this.$route.query[this.orderValuePropertyName] === this.orderValue
      }

      return result
    },

    activeFilter () {
      return this.filterOptions && this.filterOptions.metric && ((
          Object.keys(this.$route.query).includes(`${this.filterOptions.metric.filterName}${this.filterOptions.diff ? '_diff' : ''}_from`) &&
              this.$route.query[`${this.filterOptions.metric.filterName}${this.filterOptions.diff ? '_diff' : ''}_from`] !== undefined
          ) ||
          (
              Object.keys(this.$route.query).includes(`${this.filterOptions.metric.filterName}${this.filterOptions.diff ? '_diff' : ''}_to`) &&
              this.$route.query[`${this.filterOptions.metric.filterName}${this.filterOptions.diff ? '_diff' : ''}_to`]
          ) ||
          (
              Object.keys(this.$route.query).includes(`${this.filterOptions.metric.filterName}_category`) &&
              this.$route.query[`${this.filterOptions.metric.filterName}_category`]
          )
      )
    },

    buttonsVisible() {
      return this.showButtons || this.activeFilter || this.activeSort.asc || this.activeSort.desc
    },

    showToggleVisibility() {
      return this.toggleVisibility && this.filterOptions && !this.filterOptions.metric.disabled
    }
  }
}
</script>

<style lang="scss" scoped>
  .cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    line-height: 18px;

    &._row {
      flex-direction: row;

      .__right {
        margin-left: auto;
      }
    }

    &._column {
      flex-direction: column;
      justify-content: space-between;
    }

    &._big {
      height: 95px;
    }

    &._min {
      height: 34px;
    }

    &._start {
      justify-content: flex-start;
    }

    &._center {
      align-items: center;
    }

    & .__offset-left {
      margin-left: 16px;
    }

    &._nowrap {
      white-space: nowrap;
    }

    &._right {
      .buttons {
        margin-left: 0;
      }
    }

    &._full-width {
      width: max-content;
      min-width: 100%;
    }
  }

  .sort {
    display: flex;
    margin-left: 4px;

    &.__column {
      flex-direction: column-reverse;
    }

    &__btn {
      width: 18px;
      height: 18px;
      fill: #9D99AA;
      cursor: pointer;

      &:last-child {
        transform: rotate(180deg) scaleX(-1);
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    margin-left: 20px;

    &._full-width {
      width: 76px;

      .sort {
        margin-left: auto;
      }
    }

    &._full-height {
      height: 24px;
    }
  }

  .filter__btn,
  .visibility__btn {
    width: 24px;
    height: 24px;
    fill: #9D99AA;

    svg {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .visibility__btn {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: auto;
  }

  .sort__btn,
  .filter__btn,
  .visibility__btn {
    &:hover {
      fill: #0E32F5;
    }

    &._active {
      fill: #0E32F5;
    }
  }
</style>

<template>
  <div class="index-container">
    <div
      :class="['index-container__head', { '_multi-action': multiAction }]"
    >
      <slot v-if="!multiAction" name="head">
        <slot name="title">
          <div class="index-container__title-wrapper">
            <span class="index-container__title">{{ title }}</span>
            <v-hint
              v-if="titleTooltip"
              icon="info_small"
              tooltip-position="top"
            >
              <template>{{ titleTooltip }}</template>
            </v-hint>
          </div>
        </slot>

        <slot name="head-middle"></slot>

        <slot name="tooltip">
          <v-hint
            v-if="tooltip || showTooltip"
            class="index-container__hint"
            tooltip-position="left-start"
            icon="info_outline"
          >
            <span v-html="tooltip"></span>
          </v-hint>
        </slot>
      </slot>
      <slot v-else name="head-multi-action"></slot>
    </div>

    <v-preloader class="index-container-preloader" v-if="interactive && load"/>

    <div v-else-if="accessDenied">
      <div class="index-container-nodata _horizontal">
        <icon name="icon-emty-state" class="index-container-nodata__icon"/>
        <div class="index-container-nodata__content">
          <slot name="nodata">
            <div class="index-container-nodata__title">Нет доступа к разделу</div>
          </slot>
        </div>
      </div>
    </div>

    <template v-else>
      <div
        v-if="interactive && (!hasData || hasData === undefined || hasData === null)"
        :class="['index-container-nodata', `_${noDataType}`]"
      >
        <icon name="empty-state" class="index-container-nodata__icon"/>

        <div class="index-container-nodata__content">
          <slot name="nodata">
            <div class="index-container-nodata__title">{{ noData.title || 'Данные отсутствуют' }}</div>
            <div class="index-container-nodata__note" v-if="noData.note">{{ noData.note }}</div>
          </slot>
        </div>
      </div>

      <slot name="default" v-else/>

      <div class="index-container__footer" v-if="link && !link.hidden || this.$slots['footer-left'] || this.$slots['button']">
        <slot name="footer-left"></slot>

        <slot name="button">
          <v-button
            v-if="link && link.title && link.to"
            :to="link.to"
            class="index-container__link"
            type="text-main"
          >
            {{ link.title }}
            <icon class="block-bottom__link-icon" name="chevron_right"></icon>
          </v-button>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'container',

  props: {
    /* Флаг прелодера */
    load: {
      type: Boolean,
      default: false
    },

    /* Текст заголовка */
    title: {
      type: String,
      default: ''
    },

    /* Текст тултипа */
    tooltip: {
      type: String,
      default: undefined
    },

    /* Ссылка на раздел */
    link: {
      type: Object
    },

    /* Флаг наличия данных */
    hasData: {
      type: Boolean
    },

    /* Данные для отображения заглушки */
    noData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    noDataType: {
      type: String,
      default: 'horizontal',
      validator: function (value) {
        return ['vertical', 'horizontal'].indexOf(value) !== -1
      }
    },

    /* Интерактивность блока */
    interactive: {
      type: Boolean,
      default: true
    },

    accessDenied: {
      type: Boolean,
      default: false
    },

    multiAction: {
      type: Boolean,
      default: false
    },

    titleTooltip: {
      type: String,
      default: ''
    },

    showTooltip: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      config: state => state.config
    })
  }
}
</script>

<style lang="scss" src="./container.scss" scoped></style>
